import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import Masonry from "react-masonry-component";
import Container from "common/components/UI/ContainerTwo";
import Section, { BlogPost, ContentWrapper } from "./blogs.style";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { user } from "react-icons-kit/feather/user";
import { calendar } from "react-icons-kit/feather/calendar";
import { eye } from "react-icons-kit/feather/eye";
import Link from "common/components/Link";
import Image from "common/components/Image";
import Fade from "react-reveal/Fade";

export default function Blogs() {
    const Data = useStaticQuery(graphql`
        query {
            foodDeliveryJson {
                BLOGS_DATA {
                    thumbUrl {
                        publicURL
                    }
                    title
                    desc
                    link
                    author
                    date
                    views
                    tags
                }
            }
        }
    `);
    const data = Data.foodDeliveryJson.BLOGS_DATA;

    return (
        <Section id="blog">
            <Container>
                <ContentWrapper>
                    <Masonry className="masonryGrid">
                        {data.map((blog) => {
                            return (
                                <BlogPost key={blog.title}>
                                    <Fade bottom cascade>
                                        <figure>
                                            <Image
                                                src={blog.thumbUrl.publicURL}
                                                alt={blog.title}
                                            />
                                            <div className="blog-tags">
                                                {blog.tags?.map((t) => (
                                                    <span className="tag">
                                                        {t}
                                                    </span>
                                                ))}
                                            </div>
                                        </figure>
                                        <h4>{blog.title}</h4>
                                        {blog.desc && <p>{blog.desc}</p>}
                                        {blog.link && (
                                            <Link
                                                href={blog.link}
                                                className="learnMore"
                                            >
                                                Learn More{" "}
                                                <Icon icon={chevronRight} />
                                            </Link>
                                        )}
                                        <hr />
                                        <div className="blog-info">
                                            <span>
                                                <Icon icon={user} />
                                                {blog.author}
                                            </span>
                                            <span>
                                                <Icon icon={calendar} />
                                                {blog.date}
                                            </span>
                                            <span>
                                                <Icon icon={eye} />
                                                {blog.views}
                                            </span>
                                        </div>
                                    </Fade>
                                </BlogPost>
                            );
                        })}
                    </Masonry>
                </ContentWrapper>
            </Container>
        </Section>
    );
}
